<template>
  <div>
    <div id="finalMsg" class="text-center my-5 sr-only">
      <h2 class="font-weight-bold text-secondary mb-3">
        Obrigado por escolher o Cartão Paraíba
      </h2>
      <h5 class="font-weight-bold text-secondary">
        Sua proposta está em análise e em até 3 dias úteis você receberá a
        resposta no e-mail cadastrado.
      </h5>

      <button
        type="button"
        @click="reloadPage"
        class="btn btn-warning btn-pecaja text-white mt-4"
        style="font-size: 1em"
      >
        VOLTAR PARA O INICÍO
      </button>
    </div>

    <form-wizard
      v-loading="loading"
      color="#ffb400"
      error-color="#dc3545"
      ref="wizard"
      id="formWizard"
      step-size="xs"
      style="font-family: 'Open Sans', sans-serif"
      next-button-text="Continuar"
      back-button-text="Voltar"
      finish-button-text="Finalizar"
      @on-complete="onComplete"
      title=""
      subtitle=""
    >
      <!-- Tab 1 -->
      <tab-content
        data-content="tab1"
        icon="none"
        :before-change="validateFormTab1"
      >
        <p class="font-weight-bold text-secondary mt-3 mb-5">
          Para iniciarmos seu cadastro, informe seu CPF e Data de Nascimento
        </p>

        <el-form
          :model="formCad"
          class="demo-form-inline"
          :rules="tab1"
          ref="ruleTab1"
        >
          <el-form-item
            class="font-weight-bold text-secondary"
            label="CPF"
            prop="nr_cpf"
            :error="error.nr_cpf"
          >
            <el-input
              id="nr_cpf"
              data-index="0"
              v-model="formCad.nr_cpf"
              v-mask="['###.###.###-##']"
              inputmode="numeric"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Data de nascimento"
            prop="dt_nascimento"
            :error="error.dt_nascimento"
          >
            <el-input
              id="dt_nascimento"
              data-index="0"
              v-mask="['##/##/####']"
              v-model="formCad.dt_nascimento"
              inputmode="numeric"
            >
            </el-input>
          </el-form-item>
        </el-form>

        <div class="card-terms mt-5 text-justify">
          <small>
            Declaro estar ciente de minha exclusiva responsabilidade pela
            veracidade, atualidade e precisão por todas as informações
            referentes aos dados pessoais fornecidos, estando igualmente de
            acordo que a proposta seja submetida a prévia análise de crédito, e,
            caso aprovada, de já aceito o Contrato registrado no livro B-48 sob
            o nº. 14.179 no Cartório "Nazareno Araújo" - 6° ofício de Notas da
            Comarca de Teresina-PI, em 31/05/2005.
            <br /><br />
            Declaro que autorizo e concordo com a realização de consulta ao
            Sistema de Informações de Crédito (SCR) do Banco Central e que meus
            dados – e de eventuais dependentes – sejam ali registrados.
            <br />
          </small>
          <br />
          <small class="font-weight-bold mt-5">
            <el-checkbox
              v-model="acceptTerms"
              class="acceptTerms font-weight-bold"
            >
            </el-checkbox>
            <span>
              Declaro que li e aceito os
              <a
                id="terms"
                tipo_doc="0"
                class="text-primary"
                @click="viewDocs = true"
                style="cursor: pointer"
              >
                Termos de uso</a
              >,
              <a
                id="contract"
                tipo_doc="2"
                class="text-primary"
                @click="viewDocs = true"
                style="cursor: pointer"
                >Contrato de emissão e utilização</a
              >
              e
              <a
                id="pvPolicy"
                tipo_doc="1"
                class="text-primary"
                @click="viewDocs = true"
                style="cursor: pointer"
                >Aviso de Privacidade</a
              >, concordando com a
              <a
                id="plCookies"
                tipo_doc="3"
                class="text-primary"
                @click="viewDocs = true"
                style="cursor: pointer"
                >utilização de cookies</a
              >
              pelo site e com as duas declarações acima.
            </span>

            <!-- <a id="terms" class="text-primary" @click="viewDocs = true" style="cursor:pointer"> Termos de Uso </a> e 
                        <a id="contract" class="text-primary" @click="viewDocs = true" style="cursor:pointer">Contrato de emissão e utilização</a>. -->
          </small>
        </div>
      </tab-content>
      <!-- Tab 2 -->
      <tab-content
        data-content="tab2"
        icon="none"
        :before-change="validateFormTab2"
      >
        <p class="font-weight-bold text-secondary mt-3 mb-5">
          Preencha os campos abaixo com informações válidas, pois usaremos para
          liberação do seu cartão, bem como forma de contato caso sua proposta
          seja aprovada.
        </p>

        <el-form
          :model="formCad"
          class="demo-form-inline"
          :rules="tab2"
          ref="ruleTab2"
        >
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Como gostaria de ser chamado?"
            prop="no_apelido"
            :error="error.no_apelido"
          >
            <el-input
              data-index="1"
              id="no_apelido"
              v-model="formCad.no_apelido"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Qual a melhor opção para Dia do Vencimento do seu cartão?"
            prop="nr_dia_vencto"
            :error="error.nr_dia_vencto"
          >
            <el-select
              data-index="2"
              id="nr_dia_vencto"
              v-model="formCad.nr_dia_vencto"
              clearable
              placeholder="Selecione uma opção"
            >
              <el-option
                v-for="item in optionsVencimento"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Email"
            prop="no_email"
            :error="error.no_email"
          >
            <el-input
              data-index="1"
              id="no_email"
              type="email"
              v-model="formCad.no_email"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Confirmar Email"
            prop="email_check"
            :error="error.email_check"
          >
            <el-input
              data-index="1"
              id="email_check"
              type="email"
              v-model="formCad.email_check"
            ></el-input>
            <template v-if="email_dif">
              <div class="el-form-item__error">
                Os e-mails informados não correspondem.
              </div>
            </template>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Celular"
            prop="nr_fone_cliente"
            :error="error.nr_fone_cliente"
          >
            <el-input
              data-index="1"
              v-mask="['(##) # ####-####']"
              v-model="formCad.nr_fone_cliente"
              inputmode="numeric"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Telefone"
            prop="nr_fone_fixo"
            :error="error.nr_fone_fixo"
          >
            <el-input
              data-index="1"
              id="nr_fone_fixo"
              v-mask="['(##) ####-####']"
              v-model="formCad.nr_fone_fixo"
              inputmode="numeric"
            ></el-input>
          </el-form-item>
        </el-form>
      </tab-content>
      <!-- Tab 3 -->
      <tab-content
        data-content="tab3"
        icon="none"
        :before-change="validateFormTab3"
      >
        <h4 class="font-weight-bold">{{ formCad.no_apelido }},</h4>
        <p class="font-weight-bold text-secondary mt-3 mb-5">
          precisamos saber mais sobre você!
        </p>

        <el-form
          :model="formCad"
          class="demo-form-inline"
          :rules="tab3"
          ref="ruleTab3"
        >
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Sexo"
            prop="cd_sexo"
            :error="error.cd_sexo"
          >
            <el-select
              data-index="2"
              id="cd_sexo"
              v-model="formCad.cd_sexo"
              clearable
              placeholder="Selecione uma opção"
            >
              <el-option
                v-for="item in optionsSexo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Escolaridade"
            prop="fl_escolaridade"
            :error="error.fl_escolaridade"
          >
            <el-select
              data-index="2"
              id="fl_escolaridade"
              v-model="formCad.fl_escolaridade"
              clearable
              placeholder="Selecione uma opção"
            >
              <el-option
                v-for="item in optionsEscolaridade"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Estado Civil"
            prop="cd_est_civil"
            :error="error.cd_est_civil"
          >
            <el-select
              data-index="2"
              id="cd_est_civil"
              v-model="formCad.cd_est_civil"
              clearable
              placeholder="Selecione uma opção"
            >
              <el-option
                v-for="item in optionsEstadoCivil"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="formCad.cd_est_civil === '2'">
            <el-form-item
              class="font-weight-bold text-secondary"
              label="Nome do Cônjuge"
              prop="no_conjuge"
              :error="error.no_conjuge"
            >
              <el-input
                data-index="2"
                id="no_conjuge"
                v-model="formCad.no_conjuge"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="font-weight-bold text-secondary"
              label="CPF do Cônjuge"
              :error="error.nr_cpf_conjuge"
              prop="nr_cpf_conjuge"
            >
              <el-input
                data-index="2"
                id="nr_cpf_conjuge"
                v-model="formCad.nr_cpf_conjuge"
                v-mask="['###.###.###-##']"
              ></el-input>
            </el-form-item>
          </template>
        </el-form>
      </tab-content>
      <!-- Tab 4 -->
      <tab-content
        data-content="tab4"
        icon="none"
        :before-change="validateFormTab4"
      >
        <h4 class="font-weight-bold">{{ formCad.no_apelido }},</h4>
        <p class="font-weight-bold text-secondary mt-3 mb-5">
          precisamos saber onde você mora, já que vamos estreitar nossos laços.
        </p>

        <el-form
          :model="formCad"
          class="demo-form-inline"
          :rules="tab4"
          ref="ruleTab4"
          autocomplete="off"
        >
          <el-form-item
            class="font-weight-bold text-secondary"
            label="CEP"
            prop="nr_cep_residencia"
          >
            <el-input
              data-index="3"
              id="nr_cep_residencia"
              class="col-8 pl-0"
              v-mask="['########']"
              v-model="formCad.nr_cep_residencia"
              inputmode="numeric"
            ></el-input>
            <el-button
              class="col-4"
              type="success"
              round
              :loading="loading"
              @click="getCep"
              >Buscar</el-button
            >
          </el-form-item>

          <template
            v-if="formCad.nr_cep_residencia != '' && formCad.no_cidade != ''"
            id="endereco"
            class="sr-only"
          >
            <!-- formulário -->
            <el-form-item
              class="font-weight-bold text-secondary"
              label="Endereço"
              prop="no_endereco"
              :error="error.no_endereco"
            >
              <el-input
                data-index="3"
                id="no_endereco"
                v-model="formCad.no_endereco"
              ></el-input>
            </el-form-item>

            <el-form-item
              class="font-weight-bold text-secondary"
              label="Número"
              prop="nr_numero"
              :error="error.nr_numero"
            >
              <el-input
                data-index="3"
                maxlength="5"
                id="nr_numero"
                v-model="formCad.nr_numero"
              ></el-input>
            </el-form-item>

            <el-form-item
              class="font-weight-bold text-secondary"
              label="Bairro"
              prop="no_bairro"
              :error="error.no_bairro"
            >
              <el-input
                data-index="3"
                id="no_bairro"
                v-model="formCad.no_bairro"
              ></el-input>
            </el-form-item>

            <el-row>
              <el-col
                :span="width >= 1024 ? 19 : 24"
                :class="width >= 1024 ? 'mr-4' : ''"
              >
                <el-form-item
                  class="font-weight-bold text-secondary"
                  label="Cidade"
                  prop="no_cidade"
                  :error="error.no_cidade"
                >
                  <el-input
                    data-index="3"
                    id="no_cidade"
                    v-model="formCad.no_cidade"
                    :disabled="!hasNo_cidade ? false : true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="width >= 1024 ? 4 : 24">
                <el-form-item
                  class="font-weight-bold text-secondary"
                  label="UF"
                  prop="uf_residencia"
                  :error="error.uf_residencia"
                >
                  <el-input
                    data-index="3"
                    id="uf_residencia"
                    v-model="formCad.uf_residencia"
                    :disabled="!hasUf_residencia ? false : true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item
              class="font-weight-bold text-secondary"
              label="Complemento"
              prop="no_complemento"
              :error="error.no_complemento"
            >
              <el-input
                id="no_complemento"
                maxlength="50"
                v-model="formCad.no_complemento"
                placeholder="Ex: Bloco,  Apartamento, Lote, Setor e etc."
              ></el-input>
            </el-form-item>
            <el-form-item
              class="font-weight-bold text-secondary"
              label="Ponto de referência"
              prop="no_ponto_refer"
              :error="error.no_ponto_refer"
            >
              <el-input
                data-index="3"
                maxlength="50"
                id="no_ponto_refer"
                v-model="formCad.no_ponto_refer"
              ></el-input>
            </el-form-item>

            <el-form-item
              class="font-weight-bold text-secondary"
              label="Tipo de Residência"
              prop="ao_resid_tipo"
              :error="error.ao_resid_tipo"
            >
              <el-select
                data-index="3"
                id="ao_resid_tipo"
                v-model="formCad.ao_resid_tipo"
                clearable
                placeholder="Selecione uma opção"
              >
                <el-option
                  v-for="item in optionsTipoResidencia"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <template v-if="formCad.ao_resid_tipo === '2'">
              <el-form-item
                class="font-weight-bold text-secondary"
                label="Valor do Aluguel"
                prop="vl_aluguel"
                :error="error.vl_aluguel"
              >
                <el-input
                  data-index="3"
                  id="vl_aluguel"
                  v-model.lazy="price"
                  v-money="money"
                  v-model="formCad.vl_aluguel"
                ></el-input>
              </el-form-item>
            </template>

            <!-- button dialog Map -->
            <el-form-item
              class="font-weight-bold text-secondary"
              label="Loja de recebimento do cartão?"
              prop="sg_loja"
              :error="error.sg_loja"
            >
              <el-row>
                <el-col :span="20">
                  <el-select
                    v-if="this.optionsRecebimento.length != 0"
                    class="pr-2"
                    data-index="2"
                    id="sg_loja"
                    v-model="formCad.sg_loja"
                    clearable
                    placeholder="Selecione uma opção"
                    :disabled="optionsRecebimento.length == 1 ? true : false"
                  >
                    <el-option
                      v-for="item in optionsRecebimento"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <el-tooltip :content="item.label" placement="top">
                        <span>{{ item.label }}</span>
                      </el-tooltip>
                    </el-option>
                  </el-select>
                  <el-button
                    v-else
                    loading:true
                    style="width: 100%; text-align: left"
                    >Carregando ...</el-button
                  >
                </el-col>
                <el-col :span="4" class="btn-locale-resp">
                  <Map
                    class="float-right"
                    ref="map"
                    :cidade="formCad.no_cidade"
                    :estado="formCad.uf_residencia"
                    :ibge="formCad.cd_cidade_ibge"
                    :cpf="formCad.nr_cpf"
                    :nascimento="formCad.dt_nascimento"
                    @sgLoja="setSgLoja($event)"
                    @adress="setOptionsAdress($event)"
                    @cleanAdress="cleanAdress"
                  ></Map>
                </el-col>
              </el-row>
            </el-form-item>
          </template>
        </el-form>
      </tab-content>
      <!-- Tab 5 -->
      <tab-content
        data-content="tab5"
        icon="none"
        :before-change="validateFormTab5"
      >
        <h4 class="font-weight-bold">{{ formCad.no_apelido }},</h4>
        <p class="font-weight-bold text-secondary mt-3 mb-5">
          estamos quase terminando, agora precisamos saber sobre sua ocupação.
        </p>

        <el-form
          :model="formCad"
          class="demo-form-inline"
          :rules="tab5"
          ref="ruleTab5"
        >
          <el-form-item
            class="font-weight-bold text-secondary"
            label="Qual sua fonte de renda?"
            prop="tp_ocupacao"
            :error="error.tp_ocupacao"
          >
            <el-select
              @change="cleanOccupation()"
              data-index="2"
              id="tp_ocupacao"
              v-model="formCad.tp_ocupacao"
              clearable
              placeholder="Selecione uma opção"
            >
              <el-option
                v-for="item in optionsOcupacao"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="['1', '3', '4'].includes(this.formCad.tp_ocupacao)"
            class="font-weight-bold text-secondary cbo"
            label="Profissão/Ocupação"
            prop="cd_cbo"
            :error="error.cd_cbo"
          >
            <model-list-select
              :list="optionsProfissao"
              v-model="formCad.cd_cbo"
              option-value="cd_cbo"
              option-text="no_ocupacao"
              placeholder="Escolha ou pesquise sua profissão/ocupação"
              @focus="formCad.cd_cbo = ''"
            >
            </model-list-select>
            <!--
                        <select v-model="formCad.cd_cbo">
                            <option v-for="item in optionsProfissao" :key="item.cd_cbo" :value="item.cd_cbo">
                                {{ item.no_ocupacao }}
                            </option>
                        </select>
                        -->
            <!-- <el-select data-index="2" id="cd_cbo"
                            v-model="formCad.cd_cbo"
                            filterable
                            @keyup.prevent.native="searchProfession"
                            reserve-keyword
                            placeholder="Digite para pesquisar sua profissão/ocupação"
                            :loading="loadingSearch">
                            <el-option
                                v-for="item in filtroProfissao"
                                :key="item.cd_cbo"
                                :label="item.no_ocupacao"
                                :value="item.cd_cbo">
                                <el-tooltip 
                                    :content="item.no_ocupacao" 
                                    placement="top">
                                    <span>{{ item.no_ocupacao }}</span> 
                                </el-tooltip>
                            </el-option>
                        </el-select> -->

            <!--<el-autocomplete
                            data-index="2" id="cd_cbo"
                            class="w-100"
                            v-model="formCad.no_ocupacao"
                            :fetch-suggestions="querySearch"
                            placeholder="Digite para pesquisar sua profissão/ocupação"
                            @select="handleSelect"
                            clearable   
                        ></el-autocomplete>-->
          </el-form-item>

          <!-- <template  v-if="['4'].includes(this.formCad.tp_ocupacao)">
                        <el-form-item class="font-weight-bold text-secondary" label="Função" prop="no_funcao" :error="error.no_funcao">
                            <el-input data-index="4" id="no_funcao" v-model="formCad.no_funcao"></el-input>
                        </el-form-item>
                    </template> -->

          <el-form-item
            v-if="['1', '4', '5'].includes(this.formCad.tp_ocupacao)"
            class="font-weight-bold text-secondary"
            :label="
              this.formCad.tp_ocupacao == '5'
                ? 'Função ou Atividade que você exerce?'
                : 'Nome da Empresa'
            "
            prop="no_firma"
            :error="error.no_firma"
          >
            <el-input
              data-index="4"
              id="no_firma"
              v-model="formCad.no_firma"
            ></el-input>
          </el-form-item>

          <template v-if="['1', '4'].includes(this.formCad.tp_ocupacao)">
            <el-form-item
              class="font-weight-bold text-secondary"
              label="Telefone da Empresa"
              prop="nr_fone_fir"
              :error="error.nr_fone_fir"
            >
              <el-input
                data-index="4"
                id="nr_fone_fir"
                v-mask="['(##) ####-####']"
                v-model="formCad.nr_fone_fir"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="font-weight-bold text-secondary"
              label="Data de Admissão"
              prop="dt_admissao"
              :error="error.dt_admissao"
            >
              <el-input
                data-index="4"
                id="dt_admissao"
                v-mask="['##/##/####']"
                v-model="formCad.dt_admissao"
              ></el-input>
            </el-form-item>
          </template>

          <el-form-item
            v-if="formCad.tp_ocupacao != ''"
            class="font-weight-bold text-secondary"
            :label="
              this.formCad.tp_ocupacao == 1 || this.formCad.tp_ocupacao == 4
                ? 'Salário'
                : 'Valor da Renda Mensal'
            "
            prop="vl_salario"
            :error="error.vl_salario"
          >
            <el-input
              data-index="4"
              id="vl_salario"
              v-model.lazy="price"
              v-money="money"
              v-model="formCad.vl_salario"
              inputmode="numeric"
            ></el-input>
          </el-form-item>
        </el-form>
      </tab-content>
      <!-- Tab 6 -->
      <tab-content
        data-content="tab6"
        icon="none"
        :before-change="validateFormTab6"
      >
        <!-- title -->
        <h4 class="font-weight-bold">{{ formCad.no_apelido }},</h4>
        <p class="font-weight-bold text-secondary mt-3 mb-5">
          Para finalizar, nos envie sua documentação.
        </p>

        <el-form
          :model="formCad"
          class="demo-form-inline"
          :rules="tab6"
          ref="ruleTab6"
        >
          <!-- webcam -->
          <el-form-item
            class="font-weight-bold text-secondary text-center"
            label="Solicitamos uma selfie atual e de boa qualidade, onde seu rosto apareça claramente. Evite enviar fotos de documentos."
            prop="img_foto"
            :error="error.img_foto"
          >
            <el-row class="text-left">
              <template v-if="formCad.img_foto.length == 0">
                <el-button
                  v-if="hasWebCam && !this.isMobile()"
                  style="font-size: 1.3em"
                  icon="el-icon-camera-solid"
                  @click="beforeSendPhoto('cam')"
                  round
                  >Tirar Foto</el-button
                >
                <el-button
                  style="font-size: 1.2em"
                  :icon="
                    this.isMobile() ? 'el-icon-camera-solid' : 'el-icon-upload'
                  "
                  @click="beforeSendPhoto('up')"
                  round
                  >{{
                    this.isMobile() ? "Tirar Foto" : "Enviar Arquivo"
                  }}</el-button
                >
              </template>
            </el-row>

            <template
              class="mt-3"
              v-if="enableCam && accessWebcam && !enableUpPhoto && !img"
            >
              <WebCam
                class="webcam-view mt-3"
                ref="webcam"
                :deviceId="deviceId"
                width="auto"
                height="100%"
                @cameras="onCameras"
                :isFrontCam="frontCam"
              ></WebCam>
              <el-button
                @click="onCapture"
                type="danger"
                icon="el-icon-camera"
                round
              >
                Capturar Imagem</el-button
              >
            </template>

            <template v-if="img" class="mt-3">
              <el-image class="img-cam" :src="img" :preview-src-list="srcList">
              </el-image>
            </template>

            <div v-if="img" class="mt-3">
              <el-button
                @click="previewCam"
                type="info"
                icon="el-icon-search"
                round
                plain
                >Visualizar</el-button
              >
              <el-button
                @click="chageImgCam"
                type="danger"
                icon="el-icon-delete"
                round
                plain
                >Exluir</el-button
              >
            </div>

            <div
              v-if="enableCam && !accessWebcam && !img"
              class="alert alert-warning mt-3"
              role="alert"
              style="line-height: initial"
            >
              <h4 class="alert-heading font-weight-bold">Importante!</h4>
              <p>
                Para enviar uma foto sua, é necessário que você permita o acesso
                a webcam.
                <br />
                Após liberação clique no botão abaixo.
              </p>
              <hr />
              <el-button
                @click="beforeSendPhoto('cam')"
                type="warning"
                round
                plain
                >Já liberei o acesso!!</el-button
              >
            </div>
          </el-form-item>

          <!-- upload photo -->
          <el-form-item
            v-if="enableUpPhoto && !enableCam"
            class="font-weight-bold text-secondary tete"
            prop="img_foto"
            :error="error.img_foto"
          >
            <el-upload
              data-index="5"
              id="img_foto"
              class="uploadInput"
              action=""
              list-type="picture-card"
              accept="image/jpg, image/jpeg, image/png, image/bmp"
              :limit="1"
              :auto-upload="false"
              :on-change="toggleUpload"
              :on-preview="handlePreview"
              :on-remove="toggleUpload"
              :before-remove="beforeRemove"
              :file-list="formCad.img_foto"
              :http-request="handleFile"
            >
              <small class="font-weight-bold text-secondary"
                ><i class="el-icon-upload"></i> Clique aqui ou arraste e solte
                para enviar!</small
              >
            </el-upload>
          </el-form-item>

          <el-form-item
            class="font-weight-bold text-secondary"
            label="TIPO DE DOCUMENTO"
            prop="tp_documento"
            :error="error.tp_documento"
          >
            <el-radio-group
              id="tp_documento"
              data-index="5"
              v-model="formCad.tp_documento"
              @change="tipeDoc"
            >
              <el-radio id="c" label="C">CNH</el-radio>
              <el-radio id="r" label="R">RG</el-radio>
            </el-radio-group>
          </el-form-item>

          <div class="row" v-if="formCad.tp_documento != []">
            <el-form-item
              class="font-weight-bold text-secondary col-md col-sm-12"
              :label="
                this.formCad.tp_documento == 'R'
                  ? 'FOTO DA FRENTE DO RG'
                  : 'FOTO DA CNH'
              "
              prop="img_frente_doc"
              :error="error.img_frente_doc"
            >
              <el-upload
                data-index="5"
                id="img_frente_doc"
                class="uploadInput"
                action=""
                list-type="picture-card"
                accept=" image/jpg, image/jpeg, image/png, image/bmp"
                :limit="1"
                :auto-upload="false"
                :on-change="toggleUpload"
                :on-preview="handlePreview"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :file-list="formCad.img_frente_doc"
                :http-request="handleFile"
              >
                <small class="font-weight-bold text-secondary">
                  <img
                    v-if="this.formCad.tp_documento == 'C'"
                    width="60"
                    src="@/assets/images/CPF.svg"
                    alt=""
                  />
                  <img
                    v-if="this.formCad.tp_documento == 'R'"
                    width="60"
                    src="@/assets/images/RG_frente.svg"
                    alt=""
                  />
                </small>
              </el-upload>
            </el-form-item>

            <el-form-item
              v-if="formCad.tp_documento === 'R'"
              class="font-weight-bold text-secondary col-md col-sm-12"
              :label="'FOTO DO VERSO DO RG'"
              prop="img_verso_doc"
              :error="error.img_verso_doc"
            >
              <el-upload
                data-index="5"
                id="img_verso_doc"
                class="uploadInput"
                action=""
                list-type="picture-card"
                accept=" image/jpg, image/jpeg, image/png, image/bmp"
                :limit="1"
                :auto-upload="false"
                :on-change="toggleUpload"
                :on-preview="handlePreview"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :file-list="formCad.img_verso_doc"
                :http-request="handleFile"
              >
                <small class="font-weight-bold text-secondary">
                  <img
                    v-if="this.formCad.tp_documento == 'R'"
                    width="80"
                    src="@/assets/images/RG_verso.svg"
                    alt=""
                  />
                </small>
              </el-upload>
            </el-form-item>
          </div>

          <div class="row">
            <el-form-item
              v-if="['1', '4'].includes(this.formCad.tp_ocupacao)"
              class="font-weight-bold text-secondary col-md col-sm-12"
              label="COMPROVANTE DE RENDA"
              prop="img_c_renda"
              :error="error.img_c_renda"
            >
              <el-upload
                data-index="5"
                id="img_c_renda"
                class="uploadInput"
                action=""
                list-type="picture-card"
                accept=" image/jpg, image/jpeg, image/png, image/bmp"
                :limit="1"
                :auto-upload="false"
                :on-change="toggleUpload"
                :on-preview="handlePreview"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :file-list="formCad.img_c_renda"
                :http-request="handleFile"
              >
                <small class="font-weight-bold text-secondary">
                  <img width="80" src="@/assets/images/Invoice.svg" alt="" />
                </small>
              </el-upload>
            </el-form-item>

            <el-form-item
              class="font-weight-bold text-secondary col-md col-sm-12"
              label="COMPROVANTE DE RESIDÊNCIA"
              prop="img_c_residencia"
              :error="error.img_c_residencia"
            >
              <el-upload
                data-index="5"
                id="img_c_residencia"
                class="uploadInput"
                action=""
                list-type="picture-card"
                accept=" image/jpg, image/jpeg, image/png, image/bmp"
                :limit="1"
                :auto-upload="false"
                :on-change="toggleUpload"
                :on-preview="handlePreview"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :file-list="formCad.img_c_residencia"
                :http-request="handleFile"
              >
                <small class="font-weight-bold text-secondary">
                  <img width="80" src="@/assets/images/Invoice.svg" alt="" />
                </small>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>

        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </tab-content>
    </form-wizard>

    <el-drawer
      id="drawerDocs"
      title=""
      :visible.sync="viewDocs"
      size="100%"
      :destroy-on-close="true"
      :close-on-press-escape="false"
      direction="ttb"
    >
      <template v-if="drawerTerms">
        <Terms :tipo="tipo_doc" />
      </template>
      <template v-if="drawerContract">
        <Contract />
      </template>
      <template v-if="drawerPrivate">
        <PrivatePolicy :tipo="tipo_doc" />
      </template>
      <template v-if="drawerCookies">
        <Cookies />
      </template>
    </el-drawer>
    <!-- <small for=""> Campos com <strong style="color: red">*</strong> são de preenchimento obrigatório</small> -->
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ElementUI from "element-ui";
import VueFormWizard from "vue-form-wizard";
import locale from "element-ui/lib/locale/lang/pt-br";
import VueMask from "v-mask";
import money from "v-money";
import swal from "sweetalert";
import Map from "./Map";
import Cookies from "./Cookies_policy";
import Contract from "./Contract";
import Terms from "./Terms.vue";
import { WebCam } from "vue-cam-vision";
import { ModelListSelect } from "vue-search-select";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "element-ui/lib/theme-chalk/index.css";
import "vue-search-select/dist/VueSearchSelect.css";
import PrivatePolicy from "./Private_policy.vue";

Vue.use(VueAxios, axios);
Vue.use(VueFormWizard);
Vue.use(ElementUI, { locale });
Vue.use(VueMask);
Vue.use(money, { precision: 4 });

var $ = require("jquery");

export default {
  props: ["viewDocsHome"],
  components: {
    Map,
    WebCam,
    Contract,
    Terms,
    Cookies,
    ModelListSelect,
    PrivatePolicy,
  },
  data() {
    return {
      formCad: {
        nr_cpf: "", //038.311.563-95',
        dt_nascimento: "", //'23/02/1990',
        no_apelido: "", //'Arrombad',
        nr_dia_vencto: "",
        //no_cliente:'',
        no_email: "", //'milton.filho@armazemparaiba.com.br',
        email_check: "", //'milton.filho@armazemparaiba.com.br',
        nr_fone_cliente: "", //'99999999999',
        nr_fone_fixo: "",
        //no_mae:'',
        //no_pai:'',
        cd_sexo: "",
        fl_escolaridade: "", //'7',
        cd_est_civil: "", //'1',
        no_conjuge: "",
        nr_cpf_conjuge: "",
        nr_cep_residencia: "", //'64008270',
        cd_cidade_ibge: "",
        sg_loja: "",
        no_endereco: "",
        nr_numero: "",
        no_ponto_refer: "",
        no_complemento: "",
        ao_resid_tipo: "",
        vl_aluguel: "",
        no_bairro: "",
        no_cidade: "",
        uf_residencia: "",
        tp_ocupacao: "",
        no_ocupacao: "",
        cd_cbo: "",
        no_firma: "",
        nr_fone_fir: "",
        dt_admissao: "",
        vl_salario: "",
        // no_profissao:'',
        no_funcao: "",
        img_foto: [],
        tp_documento: "",
        img_frente_doc: [],
        img_verso_doc: [],
        img_c_renda: [],
        img_c_residencia: [],
      },

      // Options of selects
      optionsVencimento: [
        { value: 7, label: "Dia 07" },
        { value: 14, label: "Dia 14" },
        { value: 21, label: "Dia 21" },
        { value: 28, label: "Dia 28" },
      ],
      optionsSexo: [
        { value: "F", label: "Feminino" },
        { value: "M", label: "Masculino" },
      ],
      optionsEstadoCivil: [
        { value: "1", label: "Solteiro" },
        { value: "2", label: "Casado" },
        { value: "5", label: "Separado" },
        { value: "3", label: "Viuvo" },
        { value: "6", label: "Desquitado" },
        { value: "4", label: "Outros" },
      ],
      optionsRecebimento: [],
      optionsTipoResidencia: [
        { value: "1", label: "Casa própria" },
        { value: "2", label: "Alugada" },
      ],
      optionsEscolaridade: [
        { value: "1", label: "Não Alfabetizado(a)" },
        { value: "2", label: "Ensino Fundamental Incompleto" },
        { value: "3", label: "Ensino Fundamental Completo" },
        { value: "4", label: "Ensino Médio Incompleto" },
        { value: "5", label: "Ensino Médio Completo" },
        { value: "6", label: "Ensino Superior Incompleto" },
        { value: "7", label: "Ensino Superior Completo" },
      ],
      optionsOcupacao: [
        { value: "1", label: "Carteira assinada (CLT)" },
        { value: "2", label: "Aposentado/Pensionista" },
        {
          value: "3",
          label: "Profissional liberal ou autônomo sem vínculo de emprego",
        },
        { value: "4", label: "Servidor Público" },
        {
          value: "5",
          label: "Nenhuma das ocupações especificadas anteriormente",
        },
      ],
      optionsProfissao: [],

      // rules forms validatoions wizard
      tab1: {
        nr_cpf: [
          {
            required: true,
            min: 14,
            max: 14,
            message: "CPF deve ter 11 digitos",
            trigger: "blur",
          },
        ],
        dt_nascimento: [
          { required: true, message: "Informe sua Data de Nascimento" },
        ],
      },
      tab2: {
        no_apelido: [
          { required: true, message: "Informe como gostaria de ser chamado" },
          { max: 20, message: "Máximo de 20 caracteres", trigger: "change" },
        ],
        nr_dia_vencto: [
          {
            required: true,
            message:
              "Informe a melhor data para o Dia de Vencimento mensal do seu cartão",
            trigger: "blur",
          },
        ],
        no_email: [
          { required: true, message: "Informe seu Email" },
          {
            type: "email",
            max: 50,
            message: "Por favor, entre com um email válido",
            trigger: "blur",
          },
        ],
        email_check: [
          {
            required: true,
            type: "email",
            max: 50,
            message: "Os e-mails informados não correspondem.",
            trigger: "blur",
          },
        ],
        nr_fone_cliente: [
          { required: true, message: "Informe o numero do seu Celular" },
          {
            min: 14,
            message: "Por favor, entre com um numero de celular válido",
            trigger: "blur",
          },
        ],
      },
      tab3: {
        cd_sexo: [
          { required: true, message: "Informe seu Sexo", trigger: "blur" },
        ],
        fl_escolaridade: [
          {
            required: true,
            message: "Informe sua Escolaridade",
            trigger: "blur",
          },
        ],
        cd_est_civil: [
          {
            required: true,
            message: "Informe seu Estado Civil",
            trigger: "blur",
          },
        ],
        no_conjuge: [
          {
            required: false,
            message: "Informe o nome do Cônjuge",
            trigger: "blur",
          },
          { max: 70, message: "Máximo de 70 caracteres", trigger: "change" },
        ],
        nr_cpf_conjuge: [
          { required: false, message: "Informe seu CPF", trigger: "blur" },
          {
            min: 13,
            max: 14,
            message: "CPF deve ter 11 digitos",
            trigger: "blur",
          },
        ],
      },
      tab4: {
        nr_cep_residencia: [
          { required: true, message: "Informe seu CEP", trigger: "blur" },
          { min: 8, message: "Minimo de 8 numeros", trigger: "blur" },
        ],
        sg_loja: [
          {
            required: true,
            message: "Selecione onde você deseja receber o cartão",
            trigger: "change",
          },
        ],
        no_endereco: [
          { required: true, message: "Informe seu Endereço", trigger: "blur" },
        ],
        nr_numero: [
          {
            required: true,
            message: "Informe o Numero da casa",
            trigger: "blur",
          },
          { max: 5, message: "Maximo de 5 caracteres", trigger: "change" },
        ],
        no_bairro: [
          { required: true, message: "Informe o Bairro", trigger: "blur" },
        ],
        no_cidade: [
          { required: true, message: "Informe a Cidade", trigger: "blur" },
        ],
        uf_residencia: [
          { required: true, message: "Informe o Estado", trigger: "blur" },
        ],
        no_complemento: [
          { max: 50, message: "Maximo de 50 caracteres", trigger: "change" },
        ],
        no_ponto_refer: [
          {
            required: true,
            message: "Informe um Ponto de referência",
            trigger: "blur",
          },
          { max: 50, message: "Maximo de 50 caracteres", trigger: "change" },
        ],
        ao_resid_tipo: [
          {
            required: true,
            message: "Informe um Tipo de Residencia",
            trigger: "blur",
          },
        ],
        vl_aluguel: [
          {
            required: true,
            min: 8,
            message: "Informe o valor do seu Aluguel",
            trigger: "blur",
          },
        ],
      },
      tab5: {
        no_firma: [
          { required: true, message: "Campo obrigatório", trigger: "blur" },
          { max: 70, message: "Máximo de 45 caracteres", trigger: "change" },
        ],
        tp_ocupacao: [
          {
            required: true,
            message: "Selecione sua Ocupação",
            trigger: "blur",
          },
        ],
        cd_cbo: [
          {
            required: true,
            message: "Selecione nas opções sua Profissão, Cargo ou Ocupação",
            trigger: "blur",
          },
        ],
        dt_admissao: [
          {
            required: true,
            message: "Informe sua Data de Admissão na Empresa",
          },
        ],
        vl_salario: [
          { required: true, message: "Informe seu Salário", trigger: "blur" },
          {
            min: 8,
            message: "Valor deve ter no mínimo 2 dígitos",
            trigger: "blur",
          },
          { max: 15, message: "Máximo de 8 dígitos", trigger: "blur" },
        ],
        no_funcao: [
          { required: true, message: "Informe sua Função", trigger: "blur" },
          { max: 15, message: "Máximo de 15 caracteres", trigger: "blur" },
        ],
      },
      tab6: {
        img_foto: [
          { required: true, message: "Envie uma imagem sua", trigger: "blur" },
        ],
        tp_documento: [
          { required: true, message: "Escolha uma opção", trigger: "blur" },
        ],
        img_frente_doc: [
          {
            required: true,
            message: "Este compo e obrigatório",
            trigger: "blur",
          },
        ],
        img_verso_doc: [
          {
            required: true,
            message: "Este compo e obrigatório",
            trigger: "blur",
          },
        ],
        img_c_renda: [
          {
            required: true,
            message: "Este compo e obrigatório",
            trigger: "blur",
          },
        ],
        img_c_residencia: [
          {
            required: true,
            message: "Este compo e obrigatório",
            trigger: "blur",
          },
        ],
      },
      error: {},
      email_dif: false,
      hasNo_endereco: true,
      hasNo_bairro: true,
      hasNo_cidade: true,
      hasUf_residencia: true,
      finalCep: "",

      //size screen
      width: null,

      // Mask Money
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },

      // Params upload
      enableUpPhoto: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      showUpload: true,
      visitedDocs: false,

      //Loadin
      loading: false,
      loadingSearch: false,
      activeTabIndex: 0,

      //Search select cd_cbo
      filtroProfissao: [],
      valueProfissao: [],

      // WebCam
      hasWebCam: navigator.mediaDevices,
      accessWebcam: null,
      enableCam: false,
      img: null,
      srcList: [],
      camera: null,
      deviceId: null,
      devices: [],
      frontCam: true,
      report: null,
      visitedCam: false,
      visitedUphoto: false,

      //Contract and Usage Terms
      tipo_doc: "",
      viewDocs: false,
      drawerContract: false,
      drawerTerms: false,
      drawerPrivate: false,
      drawerCookies: false,
      acceptTerms: false,
    };
  },
  methods: {
    handleSelect(item) {
      if (item.link == "") {
        this.formCad.cd_cbo = "";
        this.formCad.no_ocupacao = "";
      } else {
        this.formCad.cd_cbo = item.link;
        this.formCad.no_ocupacao = item.value;
      }
    },

    querySearch(queryString, cb) {
      if (typeof queryString == "object") {
        queryString = queryString.target.value;
        // cb([{"value":"Digite para pesquizar..." , "link": ""}])
        // return
      }
      if (queryString == "") {
        cb([{ value: "Digite para pesquizar...", link: "" }]);
        return;
      }
      setTimeout(() => {
        Vue.axios({
          url: `${process.env.VUE_APP_URI_API}ocupacoes/?q=${queryString}`,
          withCredentials: false,
        }).then((response) => {
          cb(
            response.data.map((value) => {
              return { value: value.no_ocupacao.trim(), link: value.cd_cbo };
            })
          );
        });
      }, 500);
    },

    // Wizard validation functions
    validateFormTab1() {
      document.getElementById("pedir").scrollIntoView();
      this.error = [];
      return new Promise((resolve) => {
        this.$refs.ruleTab1.validate((valid) => {
          valid = false;
          this.loading = true;
          var data = new FormData();

          data.append("nr_cpf", this.formCad.nr_cpf.replace(/[^\d]+/g, "")),
            data.append("dt_nascimento", this.formCad.dt_nascimento),
            Vue.axios({
              method: "post",
              url: `${process.env.VUE_APP_URI_API}propostas/inicializa/`,
              withCredentials: false,
              data: data,
            })
              .then((response) => {
                let self = this;
                let dataResend = response.data;

                dataResend["nr_cpf"] = self.formCad.nr_cpf.replace(
                  /[^\d]+/g,
                  ""
                );
                dataResend["dt_nascimento"] = self.formCad.dt_nascimento
                  .split("/")
                  .reverse()
                  .join("-");

                if (!dataResend.status) {
                  if (!self.acceptTerms) {
                    swal({
                      title: `Olá!`,
                      text: "É necessário que aceite nossos termos de uso pra que possamos dar continuidade.",
                      icon: "info",
                      dangerMode: false,
                    });
                    self.loading = false;
                  } else {
                    valid = true;
                    this.loading = false;
                  }
                }

                switch (dataResend.status) {
                  case "PRÉ-APROVADA":
                    swal({
                      title: `Olá, ${dataResend.no_apelido}!`,
                      text: "Sua proposta ja está PRE APROVADA, compareca a loja de escolha para receber seu cartão.",
                      icon: "info",
                      dangerMode: false,
                    });
                    self.loading = false;
                    break;
                  case "EM ANÁLISE":
                    swal({
                      title: `Olá, ${dataResend.no_apelido}!`,
                      text: "Obigado por escolher o Cartão Paraíba!! Sua proposta ja está EM ANÁLISE, em até 3 dias úteis você receberá uma resposta no email cadastrado.",
                      icon: "info",
                      dangerMode: false,
                    });
                    self.loading = false;
                    break;
                  case "REENVIO":
                    swal({
                      title: `Olá, ${dataResend.no_apelido}!`,
                      text: "Sua proposta está pendente por incompatibilidade ou má qualidade nas imagens que foram enviadas. Precisamos que reenvie novas imagens para darmos continuidade a sua proposta.",
                      icon: "info",
                      buttons: {
                        text: "Reenviar Imagens",
                      },
                    }).then(() => {
                      self.$emit("action", dataResend);
                      valid = true;
                    });
                    break;
                  case "REPROVADA":
                    swal({
                      title: `Olá, seu Cartão Paraíba não foi aprovado no momento`,
                      text: "Tente novamente após 90 dias pelo site ou procure uma das lojas do Armazém Paraíba.",
                      icon: "info",
                      buttons: {
                        text: "OK",
                      },
                    });
                    self.loading = false;
                    break;
                }
              })
              .catch((error) => {
                let self = this;

                try {
                  let el = error.response.data;
                  self.error = {};

                  Object.keys(el).forEach(
                    (key) => (self.error[key] = el[key].join("<br>"))
                  );
                } catch {
                  swal({
                    title: "Opss! Algo de inesperado aconteceu.",
                    text: "Nossa equipe já está trabalhando para resolver, tente novamente mais tarde.",
                    icon: "warning",
                    dangerMode: false,
                  });
                  self.loading = false;
                }

                if (error.response.status == 500) {
                  swal({
                    title: "Opss! Algo de inesperado aconteceu.",
                    text: "Nossa equipe já está trabalhando para resolver, tente novamente mais tarde.",
                    icon: "warning",
                    dangerMode: false,
                  });
                  self.loading = false;
                }
                this.loading = false;
              })
              .finally(() => {
                resolve(valid);
              });
        });
      });
    },
    validateFormTab2() {
      let self = this;
      document.getElementById("pedir").scrollIntoView();
      this.error = [];

      return new Promise((resolve) => {
        this.$refs.ruleTab2.validate((valid) => {
          //valida se e-mails são iguais
          if (valid) {
            if (self.formCad.email_check != self.formCad.no_email) {
              self.email_dif = true;
              resolve();
            }
          }

          resolve(valid);
        });
      });
    },
    validateFormTab3() {
      document.getElementById("pedir").scrollIntoView();
      this.error = [];
      return new Promise((resolve) => {
        this.$refs.ruleTab3.validate((valid) => {
          resolve(valid);
        });
      });
    },
    validateFormTab4() {
      document.getElementById("pedir").scrollIntoView();
      this.error = [];
      return new Promise((resolve) => {
        let self = this;
        this.$refs.ruleTab4.validate((valid) => {
          //validates if there was no change in the CEP before sending
          if (self.formCad.nr_cep_residencia != self.finalCep) {
            self.optionsRecebimento = [];
            self.getCep();
            resolve();
          }
          self.getAllOccupations();
          resolve(valid);
        });
      });
    },
    validateFormTab5() {
      document.getElementById("pedir").scrollIntoView();
      this.error = [];
      return new Promise((resolve) => {
        this.$refs.ruleTab5.validate((valid) => {
          resolve(valid);
        });
      });
    },
    validateFormTab6() {
      document.getElementById("pedir").scrollIntoView();
      this.error = [];
      return new Promise((resolve) => {
        this.$refs.ruleTab6.validate((valid) => {
          resolve(valid);
        });
      });
    },

    // Form state control functions
    tipeDoc() {
      if (this.formCad.tp_documento === "C") {
        this.formCad.img_verso_doc = [];
      }
    },
    getCep() {
      let self = this;
      let cep = this.formCad.nr_cep_residencia.replace(/[^\d]+/g, "");

      this.loading = true;
      this.optionsRecebimento = [];

      Vue.axios({
        url: `https://viacep.com.br/ws/${cep.padStart(8, "0")}/json/`,
        withCredentials: false,
      })
        .then((response) => {
          let data = response.data;

          self.formCad.cd_cidade_ibge = data.ibge;

          this.cleanAdress();

          if (data.erro) {
            this.loading = false;

            swal({
              title: "CEP digitado não existe!!",
              text: "Verifique se o numero que você forneceu esta realmente correto.",
              icon: "warning",
              dangerMode: true,
            });

            return false;
          }

          this.formCad.cd_cidade_ibge = data.ibge;
          this.formCad.nr_cep_residencia = data.cep.toUpperCase();
          this.formCad.no_endereco = data.logradouro.toUpperCase();
          this.formCad.no_bairro = data.bairro.toUpperCase();
          this.formCad.no_cidade = data.localidade.toUpperCase();
          this.formCad.uf_residencia = data.uf.toUpperCase();
          this.finalCep = data.cep.replace("-", "");
          this.loading = false;

          // Enables the fields that had data in the query, for manual filling
          Object.keys(response.data).map(function (key) {
            switch (key) {
              case "logradouro":
                response.data[key] == ""
                  ? (self.hasNo_endereco = false)
                  : (self.hasNo_endereco = true);
                break;
              case "bairro":
                response.data[key] == ""
                  ? (self.hasNo_bairro = false)
                  : (self.hasNo_bairro = true);
                break;
              case "localidade":
                response.data[key] == ""
                  ? (self.hasNo_cidade = false)
                  : (self.hasNo_cidade = true);
                break;
              case "uf":
                response.data[key] == ""
                  ? (self.hasUf_residencia = false)
                  : (self.hasUf_residencia = true);
                break;
            }
          });
        })
        .catch(() => {
          this.cleanAdress();
          this.loading = false;
          swal({
            title: "CEP digitado não existe!!",
            text: "Verifique se o numero que você forneceu esta realmente correto.",
            icon: "warning",
            dangerMode: true,
          });
        })
        .finally(() => {
          this.AlertVisible = false;
          self.$refs.map.getCurrentGeolocation(false);
        });
    },
    cleanAdress() {
      (this.formCad.nr_cep_residencia = ""),
        (this.formCad.no_endereco = ""),
        (this.formCad.nr_numero = ""),
        (this.formCad.no_ponto_refer = ""),
        (this.formCad.no_complemento = ""),
        (this.formCad.no_bairro = ""),
        (this.formCad.no_cidade = ""),
        (this.formCad.ao_resid_tipo = "");
      this.formCad.vl_aluguel = "";
      this.formCad.uf_residencia = "";
    },
    cleanOccupation() {
      (this.formCad.cd_cbo = ""),
        (this.formCad.no_firma = ""),
        (this.formCad.nr_fone_fir = ""),
        (this.formCad.dt_admissao = ""),
        (this.formCad.vl_salario = ""),
        (this.formCad.no_funcao = "");
    },
    cleanFormCad() {
      (this.formCad.nr_cpf = ""),
        (this.formCad.dt_nascimento = ""),
        (this.formCad.no_apelido = ""),
        (this.formCad.nr_dia_vencto = null),
        (this.formCad.no_email = ""),
        (this.formCad.email_check = ""),
        (this.formCad.nr_fone_cliente = ""),
        (this.formCad.nr_fone_fixo = ""),
        (this.formCad.cd_sexo = ""),
        (this.formCad.fl_escolaridade = ""),
        (this.formCad.cd_est_civil = ""),
        (this.formCad.no_conjuge = ""),
        (this.formCad.nr_cpf_conjuge = ""),
        (this.formCad.nr_cep_residencia = ""),
        (this.formCad.cd_cidade_ibge = ""),
        (this.formCad.sg_loja = ""),
        (this.formCad.no_endereco = ""),
        (this.formCad.nr_numero = ""),
        (this.formCad.no_ponto_refer = ""),
        (this.formCad.no_complemento = ""),
        (this.formCad.ao_resid_tipo = ""),
        (this.formCad.vl_aluguel = ""),
        (this.formCad.no_bairro = ""),
        (this.formCad.no_cidade = ""),
        (this.formCad.uf_residencia = ""),
        (this.formCad.tp_ocupacao = ""),
        (this.formCad.cd_cbo = ""),
        (this.formCad.no_firma = ""),
        (this.formCad.nr_fone_fir = ""),
        (this.formCad.dt_admissao = ""),
        (this.formCad.vl_salario = ""),
        (this.formCad.no_funcao = ""),
        (this.formCad.img_foto = []),
        (this.formCad.tp_documento = ""),
        (this.formCad.img_frente_doc = []),
        (this.formCad.img_verso_doc = []),
        (this.formCad.img_c_renda = []),
        (this.formCad.img_c_residencia = []);
    },
    setSgLoja(loja) {
      this.formCad.sg_loja = loja;
    },
    setOptionsAdress(adress) {
      let self = this;
      if (adress.length == 0) {
        self.cleanAdress();
        swal({
          title: "Sentimos muito!!",
          text: "O endereço fornecido está fora da área de nosso atendimento.",
          icon: "warning",
          dangerMode: true,
        });
      }
      adress.map((item) => {
        self.optionsRecebimento.push({
          value: item.sg_loja,
          label: `PARAÍBA: ${
            item.no_endereco
          }, ${item.no_bairro.trim()}, ${item.no_cidade.toUpperCase().trim()}-${
            item.sg_uf
          } (${item.sg_loja})`,
        });
      });
    },
    getAllOccupations() {
      let self = this;
      this.loading = true;

      Vue.axios({
        url: `${process.env.VUE_APP_URI_API}ocupacoes/`,
        withCredentials: false,
      }).then((response) => {
        self.optionsProfissao = Object.freeze(response.data);
        // self.filtroProfissao = []
        // self.filtroProfissao = Object.freeze(self.optionsProfissao)

        self.loading = false;
      });
    },
    searchProfession(query) {
      let self = this;

      if (query == undefined || self.loadingSearch) {
        this.filtroProfissao = [];
        return;
      }
      if (typeof query == "object") {
        query = query.target.value;
      }
      if (query == "") {
        this.filtroProfissao = [];
        return;
      }

      this.loadingSearch = true;
      setTimeout(() => {
        Vue.axios({
          url: `${process.env.VUE_APP_URI_API}ocupacoes/?q=${query}`,
          withCredentials: false,
        })
          .then((response) => {
            this.filtroProfissao = response.data;
            return;
          })
          .finally(() => {
            self.loadingSearch = false;
          });
      }, 500);

      // .then((response) => {
      // if (query !== '') {
      //     this.loading = true;
      //     setTimeout(() => {
      //         this.loading = false;
      //         this.filtroProfissao = this.optionsProfissao.filter(item => {
      //             return item.no_ocupacao.toLowerCase().trim()
      //                 .indexOf(query.toLowerCase()) > -1;
      //         });
      //     }, 200);
      // } else {
      //     this.filtroProfissao = [{"cd_cbo": 0, "no_ocupacao": 'Digite para pesquisar!'}];
      //     // this.filtroProfissao = this.optionsProfissao;
      // }
    },
    pickTypeUpPhoto(type) {
      if (type == "cam") {
        if (navigator.mediaDevices.getUserMedia) {
          let self = this;

          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(function () {
              self.enableCam = true;
              self.enableUpPhoto = false;
              self.accessWebcam = true;
            })
            .catch(function () {
              self.enableCam = true;
              self.enableUpPhoto = false;
              self.accessWebcam = false;
              self.$notify({
                title: "Atenção!!",
                message: "O acesso a webcam não está liberado!",
                type: "warning",
              });
              // self.$message.error('Oops, O acesso a webcam não está liberado!');
            });
        }
      }
      if (type == "up") {
        if (this.isMobile()) {
          $("#img_foto .el-upload").click();
        }

        this.enableCam = false;
        this.enableUpPhoto = true;
      }
    },

    // Uploads state control functions
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeSendDocs(event) {
      let self = this;
      var id = event.currentTarget.id;

      event.preventDefault();
      this.visitedDocs = true;

      this.$alert(
        `<el-row>
                    <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
                        <el-card :body-style="{ padding: '0px' }">
                        <img src="${require("@/assets/images/send_docs.png")}" class="image">
                        <div style="padding: 14px;">
                            <span class="mb-3 font-weight-bold" style="font-weight: bold;font-size: 1.5em;">
                                Para que seu documento seja aceito sem problemas:
                            </span>
                            <br>
                            <span style="font-size: 1.2em;">
                                Anexe uma imagem de boa qualidade e com todos os dados legíveis.
                            </span>
                            </div>
                        </div>
                        </el-card>
                    </el-col>
                </el-row>`,
        {
          confirmButtonText: "OK, Entendi",
          center: true,
          dangerouslyUseHTMLString: true,
        }
      ).then(() => {
        if (id == "c") {
          self.formCad.tp_documento = "C";
        }
        if (id == "r") {
          self.formCad.tp_documento = "R";
        }
        if (id == "img_c_residencia") {
          $("#img_c_residencia .el-upload").click();
        }
      });
    },
    beforeRemove() {
      // this.$confirm(`Deseja realmente excluir ${ file.name } ?`);
      let element = this.findParentClass(event.currentTarget, "uploadInput");
      return $(`${element.id}`).removeClass("hideUpload");
    },
    findParentClass(el, className) {
      while (el.parentNode) {
        el = el.parentNode;
        if (el.className.includes(className)) return el;
      }
      return null;
    },
    toggleUpload(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      let element = this.findParentClass(event.currentTarget, "uploadInput");
      let msgMobile = this.isMobile()
        ? "<br> <br>Dica: Reduza a resolução da câmera."
        : "";

      if (!isLt2M) {
        this.$alert(
          `
                    Seu arquivo é muito grande: ${Math.round(
                      file.size / 1024 / 1024
                    )}MB. Por favor, <strong> enviar um arquivo de até 20MB </strong>.
                    ${msgMobile}`,
          "Tamanho não suportado!",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Entendi",
            type: "warning",
            center: true,
          }
        );

        this.formCad[element.id] = [];
        if (this.isMobile()) {
          $(`#${element.id}`).addClass("hideUpload");
        }
        return isLt2M;
      }

      this.formCad[element.id] = fileList;
      $(`#${element.id}`).toggleClass("hideUpload");
      this.showUpload = !this.showUpload;
    },
    handleFile() {},

    // WebCam functions
    onCapture() {
      let self = this;
      this.$refs.webcam.capture().then((blob) => {
        let typeImg = blob.split(";")[0].split(":")[1];
        let extension = typeImg.split("/")[1];
        let blobImage = self.toBlob(blob);
        let imgCam = {
          raw: new File([blobImage], `webCamImage.${extension}`, {
            type: typeImg,
          }),
        };

        self.img = blob;
        self.srcList.push(self.img);
        self.formCad.img_foto.push(imgCam);
      });
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    chageImgCam() {
      this.img = null;
      this.formCad.img_foto = [];
    },
    previewCam() {
      $(".img-cam img").click();
    },
    beforeSendPhoto(type) {
      let self = this;

      if (this.isMobile()) {
        this.enableUpPhoto = true;
      }

      //message if webcam
      if (!this.visitedCam) {
        if (type == "cam") {
          self
            .$alert(
              `<el-row>
                            <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
                                <el-card :body-style="{ padding: '0px' }">
                                <img src="${require("@/assets/images/selfie.png")}" class="image">
                                <div style="padding: 14px;">
                                    <span class="mb-3 font-weight-bold" style="font-weight: bold;font-size: 1.5em;">
                                        Para obter uma foto de boa qualidade:
                                    </span>
                                    <br>
                                    <span style="font-size: 1.2em;">
                                        Tire óculos, bonés ou a acessórios. Procure um local claro e posicione seu rosto no centro da câmera.
                                    </span>
                                    </div>
                                </div>
                                </el-card>
                            </el-col>
                        </el-row>`,
              {
                confirmButtonText: "OK, Entendi",
                center: true,
                dangerouslyUseHTMLString: true,
              }
            )
            .then(() => {
              self.visitedCam = true;
              self.pickTypeUpPhoto(type);
            });
        }
      } else {
        self.pickTypeUpPhoto(type);
      }

      //message if upload
      if (!this.visitedUphoto) {
        if (type == "up") {
          self
            .$alert(
              `<el-row>
                            <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
                                <el-card :body-style="{ padding: '0px' }">
                                <img src="${require("@/assets/images/send_photo.png")}" class="image">
                                <div style="padding: 14px;">
                                    <span class="font-weight-bold" style="font-weight: bold;font-size: 1.5em;">
                                        Anexe uma foto de boa qualidade:
                                    </span>
                                    <br>
                                    <span class="mt-3" style="font-size: 1.2em;">
                                        Sua foto não deve ter acessórios como óculos, bonés e etc.
                                    </span>
                                    </div>
                                </div>
                                </el-card>
                            </el-col>
                        </el-row>`,
              {
                confirmButtonText: "OK, Entendi",
                center: true,
                dangerouslyUseHTMLString: true,
              }
            )
            .then(() => {
              self.visitedUphoto = true;
              self.pickTypeUpPhoto(type);
            });
        }
      } else {
        self.pickTypeUpPhoto(type);
      }
    },

    //Convert image base64 to blob
    toBlob(blob) {
      var mime = blob.split(";base64,")[0].split(":")[1];
      var base64 = blob.split(";base64,")[1];
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];

      for (
        var offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
      ) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mime });
    },

    //Function of submit and finalit proposta
    onComplete: function () {
      this.loading = true;

      let self = this;
      let data = new FormData();

      Object.keys(this.formCad).map((key) => {
        let value = self.formCad[key];
        if (typeof value == "object") {
          if (value.length > 0) {
            data.append(key, value[0].raw);
          }
        } else {
          if (["vl_salario", "vl_aluguel"].includes(key)) {
            value =
              value == "" || value == null
                ? null
                : parseFloat(
                    value
                      .replace("R$", "")
                      .replace(",", "#")
                      .replace(".", "")
                      .replace("#", ".")
                  );
          }
          if (
            ["nr_fone_cliente", "nr_fone_fir", "nr_fone_fixo"].includes(key)
          ) {
            value =
              value == "" || value == null
                ? null
                : parseFloat(value.replace(/[^\d]+/g, ""));
          }
          if (["nr_cpf", "nr_cpf_conjuge"].includes(key)) {
            value = value.replace(/[^\d]+/g, "");
          }
          if (["no_bairro"].includes(key)) {
            value = value.substring(0, 20);
          }
          if (["no_endereco"].includes(key)) {
            value = value.substring(0, 45);
          }
          if (value != null) {
            data.append(key, value);
          }
        }
      });

      Vue.axios({
        method: "post",
        url: `${process.env.VUE_APP_URI_API}propostas/`,
        withCredentials: false,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          $("#finalMsg").removeClass("sr-only");
          $("#formWizard").addClass("sr-only");
          this.cleanFormCad();
          this.loading = false;
        })
        .catch((error) => {
          let self = this;
          if (error.response.data) {
            if (error.response.status != 500) {
              let el = error.response.data;
              let idError = Object.keys(error.response.data)[0];
              let tabError = document.getElementById(idError).dataset.index;
              self.error = {};

              Object.keys(el).forEach(
                (key) => (self.error[key] = el[key].join("<br>"))
              );

              self.$refs.wizard.changeTab(
                self.$refs.wizard.activeTabIndex,
                parseInt(tabError)
              );
            } else {
              swal({
                title: "Atenção!",
                text: "Algum erro inesperado aconteceu, tente novamente.",
                icon: "warning",
                dangerMode: true,
              });
            }
          } else {
            swal({
              title: "Atenção!",
              text: "Algum erro inesperado aconteceu, tente novamente.",
              icon: "danger",
              dangerMode: true,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // Reload
    reloadPage() {
      window.location.reload();
    },

    //size responsive escreen
    handleResize() {
      this.width = window.innerWidth;
    },

    //is mobile?
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    formCad: {
      handler: function (val) {
        if (val.cd_cbo != undefined || val.cd_cbo != "") {
          this.filtroProfissao = [];
        }
        // if (['1','3','4'].includes(val.tp_ocupacao)) {
        //     this.searchProfession();
        // }

        //validates that emails are the same
        if (val.email_check == val.no_email) {
          this.email_dif = false;
        }
      },
      deep: true,
    },

    //reload list of select CBO
    // filtroProfissao(){
    //     this.searchProfession
    // },

    checkNrNumber(atual) {
      atual ? (this.formCad.nr_numero = "") : "";
    },

    //manager view docs
    viewDocs() {
      let self = this;
      let type = event.currentTarget.id;

      self.tipo_doc = event.target.attributes.tipo_doc.value;
      self.drawerContract = false;
      self.drawerTerms = false;
      self.drawerPrivate = false;
      self.drawerCookies = false;

      if (type == "terms") {
        self.drawerTerms = true;
      }
      if (type == "contract" || type == "contract-mobile") {
        self.drawerContract = true;
      }
      if (type == "pvPolicy") {
        // Vue.axios("https://portaldaprivacidade.armazemparaiba.com.br/api/politica-privacidade/")
        // .then((res) => {
        //     debugger
        //     this.pivacityTerm = res.data.texto;
        // })
        // .catch((error) => {
        //     debugger
        //     // eslint-disable-next-line no-console
        //     console.log(error);
        // });

        self.drawerPrivate = true;
      }

      if (type == "plCookies") {
        self.drawerCookies = true;
      }
    },
    viewDocsHome() {
      this.viewDocs = this.viewDocsHome;
    },
  },
  created() {
    let self = this;
    //block ENTER
    $(document).keypress(function (event) {
      if (event.which == "13") {
        if (event.target.id == "nr_cep_residencia") {
          event.preventDefault();
        }
      }
    }),
      $(document).ready(function () {
        //block cut, copy and paste on input emial_check
        $("#email_check").on("cut copy paste", function (e) {
          e.preventDefault();
        });

        //prevente click upload docs
        $("#c, #r, #img_c_residencia").click(function () {
          if (self.visitedDocs) {
            return;
          } else {
            self.beforeSendDocs(event);
          }
        });

        $(".el-drawer__close-btn").click(function () {
          self.$emit("viewDocsHome", false);
        });
      });

    //adapt responsive items according to the screen size
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script> 
<style>
#drawerDocs .el-drawer__close-btn {
  background: red;
  color: white;
  border-radius: 50%;
}
.cbo .el-input__inner::placeholder {
  color: rgba(0, 0, 0, 0.589);
}
.cbo .ui.fluid.dropdown {
  display: flex;
  min-height: 38px;
}
.cbo .ui.dropdown input {
  font-size: initial;
}
.cbo .ui.search.dropdown > .text {
  font-weight: 500;
}
</style>